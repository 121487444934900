import React from 'react';

import SEO from '../../../components/seo';
import SimpleHero from '../../../components/simple-hero';
import Navigation from '../../../components/navigation';
import { Column, ColumnSection } from '../../../components/column-section';
import RelatedNewsSection from '../../../components/related-news-section';

import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';
import { PrimaryButton } from '../../../components/buttons';
import { FormattedMessage } from 'react-intl';
import { Languages } from '../../../tools/languages';
import ContactCTA from '../../../components/contact-cta';

const EnglishSupportDetailPage = ({ location, data, language = Languages.EN }) => {
  const supportItem = data.supportItem;
  const seo = supportItem.seo;
  const pathName = location.pathname;

  return (
    <>
      <SEO
        pathName={pathName}
        title={seo.title ?? supportItem.title}
        description={seo.description}
        lang={language}
      />
      <Navigation transparent />
      <main>
        <SimpleHero
          image={
            supportItem.image
              ? supportItem.image.childImageSharp.original.src
              : 'https://via.placeholder.com/640?text=Bavak'
          }
          alt={supportItem.title}
        >
          <h1>{supportItem.title}</h1>
          <div style={{ marginTop: '32px' }}>
            <PrimaryButton to={`/contact`}>
              <FormattedMessage id="hero-contact-cta" />
            </PrimaryButton>
          </div>
        </SimpleHero>
        <ColumnSection>
          <RelatedNewsSection language={language} />
          <Column>
            <ReactMarkdown>{supportItem.content}</ReactMarkdown>
          </Column>
        </ColumnSection>
        <ContactCTA language={language} />
      </main>
    </>
  );
};

export const query = graphql`
  query EnglishSupportDetail($slug: String!) {
    supportItem: strapiSupportItems(slug: { eq: $slug }) {
      slug
      seo: EnglishSEO {
        title
        description
      }
      title: title_en
      content: content_en
      image {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  }
`;

export default EnglishSupportDetailPage;
